// Welcome to Code in Framer
// Get Started: https://www.framer.com/docs/guides/

// import Example from "https://framer.com/m/framer/Example.js@^1.0.0"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */

import { addPropertyControls, ControlType } from "framer"

export default function Component_input(props) {
    // - Find inspiration: https://www.framer.com/developers/

    const containerStyle = {
        position: "relative",
        display: "flex",
        alignItems: "center",
    }

    const inputStyle = {
        backgroundColor: "white",
        outline: "none",
        height: "35px",
        width: "100%",
        paddingLeft: "14px",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "14px",
        fontSize: "10px",
        color: "#939393",
        border: "1px solid #EDEDED",
        borderRadius: "2px",
    }

    return (
        <div style={containerStyle}>
            <div
                style={{
                    position: "absolute",
                    left: "18px",
                    display: "flex",
                    alignItems: "center",
                    pointerEvents: "none",
                    color: "#626262",
                }}
            ></div>
            <input
                type={props.type}
                style={inputStyle}
                placeholder={props.placeholder}
            />
        </div>
    )
}

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style

// Component_input.defaultProps = {
//     placeholder: "Search on the Blog",
// }

addPropertyControls(Component_input, {
    placeholder: {
        text: "Placeholder",
        defaultValue: "Placeholder",
        type: ControlType.String,
    },
    type: {
        text: "text",
        defaultValue: "text",
        type: ControlType.String,
    },
    // function: {
    //     type: ControlType.Enum,
    //     defaultValue: "Clear",
    //     displaySegmentedControl: true,
    //     segmentedControlDirection: "vertical",
    //     options: ["a", "b", "c"],
    //     optionTitles: ["Clear", "Blog", "Templates"],
    // },
})
